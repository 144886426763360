.billing-form-padding {
    padding: 4% 21.5% 0 21.5%;
}

.billing-item {
    padding-bottom: 0px !important;
}

.billing-section-title {
    margin-top: 2.5em;
    margin-left: 1rem;
    font-weight: 500;
    font-size: 18px;
}

.billing-button-grid {
    margin-top: 16px !important;
}