.checkbox-right{
    margin-left: 44% !important;
}

.recruiter-icons {
    margin-right: 34% !important;
}

.recruiter-left-image {
    margin-right: 33.5%;
}

.recruiter-top-text {
    margin-bottom: 0px;
}

.recruiter-bottom-text {
    margin-top: 0.2em;
}

.form-icon-items {
    display: flex;
    justify-content: center;
    padding-left: 30%;
    padding-right: 30%;
    text-align: center;
}

.entry-reference-item {
    margin-bottom: 60px;
    padding-top: 0px;
}

.entry-btn-container {
    text-align: center;
    margin-top: 15px;
}