.freelance-title-text {
    margin-top: 0.3em;
    margin-left: 1rem;
    margin-bottom: 2rem;
    font-weight: 500;
    font-size: 18px;
}

.freelance-btn-grid {
    text-align: center;
    margin-top: 15px;
}

.freelance-upload-text {
    margin-top: 0.1em;
    margin-left: 1rem;
    font-weight: 500;
    font-size: 18px;
}

.proceed-button {
    width: 180px;
    background: var(--primary-blue);
}

.freelance-select {
    border-radius: 12px;
}

.freelance-select-control { 
    width: 100%;
    padding: 0;
    margin: 0;
}