.logo-container {
    display: flex;
    justify-content: center;
    margin-top: 6%;
}

.success-main-text-container {
    display: flex;
    justify-content: center;
    margin-top: 2%;
}

.success-main-text {
    text-align: center;
    font-size: 28px;
    padding: 0.3% 27%;
    color: var(--dark-title)
}

.success-btn-container {
    display: flex;
    justify-content: center;
    margin: 3% 25%;
}