.signup-content-container {
    padding: 8.5% 9% 8.4% 14%;
}

.signup-logo-container {
    display: flex;
    justify-content: center;
    padding-top: 9vh;
    margin-bottom: 7vh
}

.signup-center-items {
    display: flex;
    justify-content: center;
}

.invalid-input {
    color: var(--highlight-red);
    text-align: left;
    font-size: 12px;
}

.account-container {
    margin-top:280px;
    text-align: center;
}