.header-logo-image {
    height: 35px;
    margin-top: 10px;
}

.tool-bar-grid {
    text-align: center;
    color: var(--base-white)
}

.toolbar-icon-container {
    margin-top: 11.5px;
}

.tool-bar-icon {
    height: 35px;
    margin-right: 18px;
}

.toolbar-search-icon {
    margin-left: 2em
}