@import url("https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,700;1,400&family=Roboto&display=swap");

:root {
    --primary-blue: #4D6CD9;
    --background-white: #F7F8FA;
    --dark-title: #30374C;
    --light-title: #626880;
    --light-background: #717982;
    --highlight-red: #FF3B2D;
    --base-white: #ffffff;
}

body{
    font-family: 'Nunito';
}

.side-image{
    display: table-cell;
    background-color: #1749C7;
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;
    background-image: radial-gradient(var(--primary-blue) 30.47%, rgba(77, 108, 217, 0) 100%);
    width: 50%;
    height: 100vh;
}

.side-content{
    display: table-cell;
    width: 50vw;
    height: 100vh;
    background-color: var(--base-white);
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;
    vertical-align: top;
}

.side-content-body{
    padding: 4% 20%;
    text-align: center;
    color: var(--dark-title);
}

.text-input{
    margin-top: 8vh;
    margin-bottom: 30px;
}

.side-image-text {
    padding-left: 10vw;
    padding-right: 10vw;
    color: var(--base-white);
    text-align: center;
    margin-bottom: 5px;
    margin-top: 5px;
}

.main-body-content {
    font-size: 1.3rem;
    color: var(--light-background);
    background: url("../public/assets/images/signup_bg.png"); background-size: 100% 100%;
}

#side-content-body-head {
    font-size: 25px;
    margin-bottom: 2vh;
    font-weight: 600;
}

#wel-line {
    height: 6px;
    width: 40px;
    background-color: #4A68D4;
    border-radius: 5px;
    margin-left: auto;
    margin-right: auto;
}

#wel-sub-txt {
    font-size: 13px;
    text-align: center;
}

.side-content-otp {
    font-size: 20px;
    font-weight: 500;
    text-align: center;
    margin-top: 11vh;
}

#form-group {
    margin-left: 25px;
    margin-right: 25px;
}

#form-group i {
    margin-left: -30px;
    cursor: pointer;
  }
#input-field-wrap {
display: flex;
align-items: center;
width: 100%;
justify-content: space-evenly;
align-items: center;
}
#password {
    height: 32px;
    width: 32px;
    font-size: 18px;
    color: var(--light-title);
    border: 2px solid #4A68D4;
    text-align: center;
    border-radius: 8px;
}
.invalidate-button {
    background-color: #E5E5E5 !important;
    color: #A4A4A4 !important;
    border-radius: 12px !important;
}

.validate-button {
    background-color: var(--primary-blue) !important;
    color: var(--base-white) !important;
    border-radius: 12px !important;
}

.change-text-light {
    color: var(--light-title);
}

.form-encapsulate {
    width: auto;
    height: 100%;
}

.header-banner-form {
    height: 51vh;
    width: 100%;
    background: url("../public/assets/images/profile_banner.png"); background-size: 100% 100%;
}

.form-card-holder {
    background-color: #F1F7FF;
}

.form-body-card {
    display:flex;
    /* justify-content: center; */
    margin-left: 18%;
    margin-right: 18%;
    position: relative;
    background-color: var(--base-white);
    bottom: 12vh;
    z-index: 1;
    /* align-items: center; */
    border-radius: 16px;
    flex-direction: column;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
}

.form-body-content {
    margin-top: 9%;
    font-size: 20px;
    font-weight: 500;
    text-align: center;
    margin-left: 4em;
    margin-right: 4em;
}

.form-heading-text {
    font-size: 24px;
    color: var(--base-white);
    font-weight: 500;
}

.navbar-header {
    color: var(--base-white);
    background-color: transparent;
    margin-top: 11.5px;
}

.navbar-header button {
    color: var(--base-white);
    margin-left: 3.5em;
}

.radius-button {
    border-radius: 12px !important;
    color: var(--base-white);
    height: 42px;
}

.outlined-radius-button {
    border-radius: 12px !important;
    color: var(--primary-blue);
    height: 42px;
}

.form-text {
    padding: 3% 21.5% 0 21.5%;
    color: var(--dark-title);
}

.form-text p {
    color: var(--dark-title);
    font-size: 16px;
}

.asterisk-span {
    color: #db3131;
}

.form-body-overlay {
    padding: 3% 21.5% 0 21.5% !important;
}

.center-loader {
    display: flex;
    justify-content: center;
    width: 100%;
}

.next-button {
    background: var(--primary-blue) !important;
}

.form-top-grid {
    max-width: 450px !important;
    margin-left: auto !important;
    margin-right: auto !important;
}

.base-header {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80%
}

.footer-font {
    font-size: 12px;
}

.text-center-align {
    text-align: center;
}

.footer-container {
    margin-top: 50px;
    text-align:center;
}

.account-text {
    font-size: 13px;
}

.signin-text {
    color: var(--primary-blue);
    text-decoration: none;
    font-size: 15px;
}

.base-spinner-container {
    display: flex;
    justify-content: center;
    margin-top: 2em;
}

.mandatory-text {
    font-size: 12.5px;
    color: var(--light-title);
}

.MuiGrid-item {
    padding-left: 0px !important;
}

.add-team-grid {
    margin-bottom: 16px !important;
}

.add-team-icon {
    margin-right: 8px;
}

.step-clickable .MuiStepLabel-iconContainer{
    cursor: pointer;
}