.side-content-padding {
    padding-top: 4vh;
}

.otp-back-btn {
    margin-left: 1vw;
}

.invalid-otp-text {
    color: var(--highlight-red);
    font-size: 12px;
}

.verify-btn-container {
    text-align: center;
    margin-top: 30px;
}

.otp-timer-text {
    font-size: 13.5px;
    color: var(--highlight-red);
}

.otp-account-container {
    margin-top: 280px;
    text-align: center;
}

.otp-account-text {
    font-size: 13px;
}