.highlight-button {
    border: 1.5px solid #AAB0CB !important;
    border-radius: 12px !important;
    height: 42px !important;
    margin-bottom: 15px !important;
}

.zero-bottom-margin {
    margin-bottom: 0px !important;
}

.highlight-button-grid {
    padding-top: 5px !important;
}

.radio-group-margin {
    margin-top: 10px;
}

.list-checked-icon {
    margin-right: 8px !important;
}

.list-chip-style {
    color: var(--primary-blue) !important;
    font-weight: 500 !important;
    border: 1.5px solid var(--primary-blue) !important;
    margin: 6px !important;
    margin-top: 12px !important;
}

.typography-item {
    margin-bottom: 0 !important;
    padding-bottom: 0 !important;
}

.highlight-grid-pt {
    padding-top: 0px !important;
}

.btn-grid-margin {
    margin-top: 16px !important;
}